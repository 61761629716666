/**
 * Bootstrap accordion component autoplay function
 */
export default (() => {
  document.addEventListener('DOMContentLoaded', function () {
    const accordions = document.querySelectorAll('.accordion-autoplay')

    const observerOptions = {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.1, // Trigger when 10% of the accordion is visible
    }

    accordions.forEach((accordion) => {
      const accordionItems = accordion.querySelectorAll('.accordion-collapse')
      const accordionButtons = accordion.querySelectorAll('.accordion-button')
      let currentIndex = 0

      const intervalTime = parseInt(accordion.dataset.bsAutoplaySpeed) || 3000
      let autoplayInterval
      let progressInterval

      // Function to reset all progress bars
      function resetProgressBars() {
        const progressBars = accordion.querySelectorAll('.accordion-progress')
        progressBars.forEach((bar) => {
          bar.style.width = '0%' // Reset width to 0%
        })
      }

      // Function to create progress bars dynamically
      function createProgressBar() {
        accordionItems.forEach((item) => {
          const progressBar = document.createElement('div')
          progressBar.classList.add('accordion-progress')
          progressBar.style.width = '0%' // Initial width is 0
          item.appendChild(progressBar) // Append progress bar inside the accordion item
        })
      }

      // Function to start the progress bar
      function startProgressBar(index) {
        let progress = 0
        const increment = 100 / (intervalTime / 100) // Percentage increment
        const progressBar = accordionItems[index].querySelector(
          '.accordion-progress'
        )

        clearInterval(progressInterval)

        progressInterval = setInterval(() => {
          progress += increment
          if (progress >= 100) {
            progress = 100
            clearInterval(progressInterval)
          }
          progressBar.style.width = `${progress}%`
        }, 100)
      }

      // Function to show the next accordion item with animation
      /* eslint-disable no-undef */
      function showNextAccordion() {
        const currentAccordion = new bootstrap.Collapse(
          accordionItems[currentIndex],
          {
            toggle: false,
          }
        )
        currentAccordion.hide()

        resetProgressBars()

        currentIndex = (currentIndex + 1) % accordionItems.length

        const nextAccordion = new bootstrap.Collapse(
          accordionItems[currentIndex],
          {
            toggle: false,
          }
        )
        nextAccordion.show()

        startProgressBar(currentIndex)

        // Synchronize binded content
        switchContent(currentIndex)
      }
      /* eslint-enable no-undef */

      // Function to start autoplay with reset
      function startAutoplay() {
        stopAutoplay()
        autoplayInterval = setInterval(showNextAccordion, intervalTime)
        startProgressBar(currentIndex)
      }

      // Function to stop autoplay
      function stopAutoplay() {
        clearInterval(autoplayInterval)
        clearInterval(progressInterval)
      }

      // Create progress bars
      createProgressBar()

      // Handle user interaction
      accordionButtons.forEach((button, index) => {
        button.addEventListener('click', function () {
          stopAutoplay()
          currentIndex = index
          resetProgressBars()
          startProgressBar(currentIndex)
          switchContent(currentIndex) // Sync binded content with active accordion
          startAutoplay()
        })
      })

      // Observe the accordion for visibility
      const accordionObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            startAutoplay() // Start autoplay if accordion is in view
          } else {
            stopAutoplay() // Stop autoplay if accordion is out of view
          }
        })
      }, observerOptions)

      accordionObserver.observe(accordion)

      // ** New Logic for binded content **
      function switchContent(index) {
        // Assuming bindedContent is a sibling or child of the accordion
        const bindedContent = document.querySelector('.binded-content')
        const bindedItems = bindedContent.querySelectorAll('.binded-item')

        // Deactivate all binded items
        bindedItems.forEach((item) => {
          item.classList.remove('active')
        })

        // Activate the corresponding binded item
        bindedItems[index].classList.add('active')
      }
    })
  })
})()
