/**
 * Starter JavaScript for disabling form submissions if there are invalid fields
 */

export default (() => {
  const forms = document.querySelectorAll('.needs-validation')

  if (forms.length === 0) return

  forms.forEach((form) => {
    form.addEventListener(
      'submit',
      (event) => {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

        form.classList.add('was-validated')

        // Custom select validation on form submit
        const customSelects = form.querySelectorAll('.form-custom-select')

        customSelects.forEach((customSelect) => {
          const selectInput = customSelect.querySelector('.visually-hidden')
          const selectButton = customSelect.querySelector('.form-select')

          if (selectInput.validity.valid) {
            selectButton.classList.remove('is-invalid')
            selectButton.classList.add('is-valid')
          } else {
            selectButton.classList.remove('is-valid')
            selectButton.classList.add('is-invalid')
          }
        })
      },
      false
    )

    // Custom select validation on change
    const customSelects = form.querySelectorAll('.form-custom-select')

    customSelects.forEach((customSelect) => {
      const selectInput = customSelect.querySelector('.visually-hidden')
      const selectButton = customSelect.querySelector('.form-select')

      customSelect.addEventListener('click', () => {
        if (form.classList.contains('was-validated')) {
          if (selectInput.validity.valid) {
            selectButton.classList.remove('is-invalid')
            selectButton.classList.add('is-valid')
          } else {
            selectButton.classList.remove('is-valid')
            selectButton.classList.add('is-invalid')
          }
        }
      })
    })
  })
})()
