/**
 * Content carousel (slider) with extensive options to control behaviour and appearance
 * @requires https://swiperjs.com/
 */

export default (() => {
  // Get all elements with the 'swiper' class
  const carousels = document.querySelectorAll('.swiper')

  // Iterate over each element using the built-in forEach method
  /* eslint-disable no-unused-vars */
  carousels.forEach((value, index) => {
    let options, images

    // Parse swiper options from the data attribute
    if (value.dataset.swiperOptions !== undefined) {
      options = JSON.parse(value.dataset.swiperOptions)
      images = options?.thumbnails?.images
    }

    // Check if thumbnails are enabled
    if (options?.thumbnails) {
      // Extend options with pagination settings for thumbnails
      options.pagination = {
        el: options.thumbnails.el,
        clickable: true,
        bulletActiveClass: 'active',
        renderBullet: (index, className) => {
          return `<div class='swiper-thumbnail ${className}'>
            <img src='${images[index]}' alt='Thumbnail'>
          </div>`
        },
      }
    }

    // Create a new Swiper instance
    /* eslint-disable no-undef */
    const swiper = new Swiper(value, options)
    /* eslint-enable no-undef */

    // Check if a controlled slider is specified
    if (options?.controlledSlider) {
      const controlledSlider = document.querySelector(options.controlledSlider)
      const controlledSliderOptions =
        controlledSlider?.dataset.swiperOptions &&
        JSON.parse(controlledSlider.dataset.swiperOptions)

      // Create a new Swiper instance for the controlled slider
      /* eslint-disable no-undef */
      const swiperControlled = new Swiper(
        controlledSlider,
        controlledSliderOptions
      )
      /* eslint-enable no-undef */
      swiper.controller.control = swiperControlled
    }

    // Handle the active index change event for binded content
    const activeIndexChanged = (e) => {
      const targetItems = document.querySelectorAll(
        e.slides[e.activeIndex].dataset.swiperBinded
      )
      const previousItems = document.querySelectorAll(
        e.slides[e.previousIndex].dataset.swiperBinded
      )

      // Remove 'active' class from the previous item and add it to the target item
      previousItems.forEach((item) => {
        item.classList.remove('active')
      })

      targetItems.forEach((item) => {
        item.classList.add('active')
      })
    }

    // Check if binded content is specified
    if (options?.bindedContent) {
      // Attach the active index change event listener
      swiper.on('activeIndexChange', activeIndexChanged)
    }
  })
})()
