/* eslint-disable no-unused-vars, no-undef */

// Get all elements with Simplebar
const simplebarElements = document.querySelectorAll('[data-simplebar-tracking]')

// Iterate over each element and add an event listener
simplebarElements.forEach((simplebarElement) => {
  const simplebarInstance = new SimpleBar(simplebarElement)

  // Add a scroll event listener for each container
  simplebarElement
    .querySelector('.simplebar-content-wrapper')
    .addEventListener('scroll', () => {
      const contentWrapper = simplebarElement.querySelector(
        '.simplebar-content-wrapper'
      )
      const scrollTop = contentWrapper.scrollTop
      const scrollHeight = contentWrapper.scrollHeight
      const clientHeight = contentWrapper.clientHeight

      // Check if the user has reached the end of the content
      if (scrollTop + clientHeight >= scrollHeight) {
        simplebarElement.classList.add('scrolled-to-end')
      } else {
        simplebarElement.classList.remove('scrolled-to-end')
      }
    })
})

/* eslint-enable no-unused-vars, no-undef */
